<template>
  <PageContainer>
    <PageTitle :breadcrumb="{name: 'settings'}" class="lg:w-main-container">
      {{ trans('BUTTON:BackToSettings') }}
    </PageTitle>
    <div class="w-full lg:w-min space-y-3 lg:space-y-6">
      <t-card class="w-full lg:w-main-container">
        <div class="w-full flex flex-col space-y-3">
          <div class="font-semibold">
            {{ trans('LABEL:SubscriptionStatus')
            }}
          </div>
          <div class="w-full flex flex-col lg:flex-row space-y-3 lg:space-y-0 justify-between pl-1.5 lg:pl-3">
            <div class="flex justify-start">
              <span v-if="currentSubscription"
                :class="`text-${currentSubscription.message.color}`"
              >
                {{currentSubscription.message.text}}
              </span>
            </div>
            <div class="flex justify-end">
              <Button
                :variant="{'buttonRedOutlined':userData.isSubscriptionActive,'buttonRed':!userData.isSubscriptionActive}"
                :to="{name: 'subscription'}"
                :text="trans(userData.isSubscriptionActive ? 'BUTTON:SubscriptionExtend' : 'BUTTON:SubscriptionBuy')"
              />
            </div>
          </div>
        </div>
      </t-card>
      <t-card class="w-full lg:w-main-container">
        <div class="font-semibold">
          {{ trans('LABEL:SubscriptionHistory') }}
        </div>
        <div class="flex flex-col lg:flex-row py-3">
          <div
            v-if="subscriptionsData && subscriptionsData.length > 0"
            class="flex flex-col w-full pl-1.5 lg:pl-3"
          >
            <div
              class="flex flex-col w-full"
              v-for="(subscription, index) in subscriptionsData" :key="index"
            >
              <div v-if="lastPaymentPending && index === 0">
                <Loader small no-border :text="trans('LABEL:WaitingForPaymentResponse')"/>
                <div class="w-full flex flex-row justify-end text-s text-badgeGrayedDark">
                  <div class="hidden lg:block">{{ trans('LABEL:InitiatedAt') }}:&nbsp;</div><div>{{ lastPaymentPendingDate }}</div>
                </div>
                <hr class="my-3" v-if="index !== subscriptionsData.length - 1">
              </div>
              <div :class="`text-badge${subscription.isActive? subscription.isExpiring ? 'Info' : 'Success' : 'Grayed'}`">
                {{trans(subscription.packageName)}} <span class="text-grayed">|</span> <span
                  :class="`text-badge${subscription.isActive?'Black' : 'Grayed'}`"
                >{{ trans(subscription.statusDesc) }}</span>
              </div>
              <span class="text-base pl-1.5 lg:pl-3">
                <div class="flex flex-col" :class="`text-badge${subscription.isActive?'Black' : 'Grayed'}`">
                  <span>
                    {{ trans('LABEL:Price') }}:&nbsp;<span class="text-base">{{ subscription.totalAmount }}</span>
                  </span>
                  <span>
                    {{ trans('LABEL:DaysAmount') }}:&nbsp;<span class="text-base">{{ subscription.days }}&nbsp;
                      <span v-if="subscription.discountDays !== null">
                        +&nbsp;{{ subscription.discountDays + ' (' + trans('LABEL:DiscountCode') + ': ' + subscription.discountName + ')'}}
                      </span>
                    </span>
                  </span>
                  <span v-if="subscription.validTo">
                    {{ trans('LABEL:SubscriptionValidTo') }}:&nbsp;<span class="text-base"
                      :class="`text-badge${subscription.isActive?
                        subscription.isExpiring ?
                          'Info'
                          : subscription.latestSubscriptionId===subscription.id?
                            'Success'
                            :'Black'
                        : 'Grayed'}`"
                    >{{ subscription.validTo }}
                    </span>
                  </span>
                </div>
              </span>
              <div class="w-full flex flex-row justify-end text-s"
                :class="`text-badge${subscription.isActive?'GrayedDark' : 'Grayed'}`"
              >
                <div class="hidden lg:block">{{ trans('LABEL:PurchasedAt') }}:&nbsp;</div><div>{{ subscription.createdAt }}</div>
              </div>
              <hr class="my-3" v-if="index !== subscriptionsData.length - 1">
            </div>
          </div>
          <Loader v-else small no-border/>
        </div>
      </t-card>
    </div>
  </PageContainer>
</template>

<script>
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Button from "@/utils/crud/components/Button";
import ContactForm from "@/views/content/components/ContactForm";
import Modal from "@/utils/modal/Modal";
import Loader from "@/views/Loader";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";

export default {
  name: "SubscriptionDetails",
  components: {Loader, Modal, ContactForm, Button,  PageTitle, PageContainer},
  data() {
    return {
      isShowContactModal: false,
      getHeightOfScreenWidth:getHeightOfScreenWidth,
    }
  },
  watch: {
    lastPaymentPending: {
      immediate: true,
      handler(status) {
        if(status) {
	        window.Echo.encryptedPrivate(`payment.${this.userData.username}`).listen('PaymentDone', (e) => {
		        this.updateSubscriptionAndDashboard();
						this.fetchSubscriptionDataRequest();
		        this.setIsPaymentStatusPending(null);
	        })
        } else {
	        this.updateSubscriptionAndDashboard();
        }
      }
    },
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('subscriptions', ['subscriptionsData', 'lastPaymentPending','lastPaymentPendingDate', 'currentSubscription'])
  },
  methods: {
    ...mapActions('subscriptions', ['fetchSubscriptionDataRequest', 'fetchLastPaymentStatus', 'fetchSubscriptionStatusInfo']),
    ...mapMutations('subscriptions', ['setIsPaymentStatusPending', 'pushToSubscriptionsData']),
    ...mapMutations('account', ['setUserSubscription']),
	  ...mapActions('dashboard', ['fetchDashboardData']),
	  ...mapActions('account', ['updateSubscriptionAndDashboard']),
    ...mapActions('alert', ['success', 'error']),

    logConsole(text) {
      console.log(text);
    }

  },
  mounted() {
    if(this.$route.query.error) {
      this.error({message: this.trans('ALERT:SubscriptionPurchaseFailed')})
    }
    if(this.$route.query.type === 'nonfree' && !this.$route.query.error) {
      this.success({message: this.trans('ALERT:SubscriptionPurchaseSuccess')})
    }
    if(this.$route.query.success && this.$route.query.type === 'free') {
      this.success({message: this.trans('ALERT:FreeSubscriptionPurchaseSuccess')})
    }

		this.fetchLastPaymentStatus();


  },
  beforeDestroy() {
    window.Echo.leave(`payment.${this.userData.username}`);
    this.setIsPaymentStatusPending(null);
  },

}
</script>

